$xantura_primary: #5464B0;
$secondary: #F6F5F8 !default;


$xantura_primary10: #5464B010;
$xantura_primary20: #5464B020;
$xantura_primary30: #5464B030;
$xantura_primary40: #5464B040;
$xantura_primary50: #5464B050;
$xantura_primary60: #5464B060;
$xantura_primary70: #5464B070;
$xantura_primary80: #5464B080;
$xantura_primary90: #5464B090;
$xantura_primary100: #5464B0;


$font-family-base: "Montserrat" !default;