@import "_pallet";

.toggle-wrapper {
    .toggle {
        display: inline-block;
        border: 1px solid $xantura_primary;
        padding: 0.625rem 1.188rem;
        font-weight: 700;
        background-color: white;
        border-right: none;

        &:first-child {
            border-radius: 0.625rem 0 0 0.625rem;
        }

        &:last-child {
            border-right: 1px solid $xantura_primary;
            border-radius: 0 0.625rem 0.625rem 0;
        }

        &:hover {
            cursor: pointer;
        }

        &.active {
            background-color: $xantura_primary;
            color: white;
        }
    }
}