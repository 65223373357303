.engagement-grid {
    margin-left: 0.938rem;
    margin-right: 0.938rem;
    min-height: 300px;

    .filter-content .filter-header {
        background-color: #E6E7F2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.9rem;
    }

    .engagement-header {
        background-color: #E6E7F2;
        // display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
    }

    .filter-content-ET {
        border-radius: 15px 15px 0px 0px;
    }

    .filter-content {
        border-radius: 15px 15px 0px 0px;
    }

    .involve-case-action-container {
        background-color: #ffffff;
        border: none;
        padding: 1rem 2rem;
        font-weight: 600;
        border-radius: 0.625rem;
        color: $xantura_primary;
        appearance: none;
        display: block;
    }

    .column-container {
        padding-right: 0.625rem;
        width: 100%;

        @media (max-width: 768px) {
            padding-bottom: 0.625rem;
        }
    }

    .btn-info-close {
        background: #E6E7F2;
        margin-right: 0.625rem;
        width: max-content;
        padding: 0.9rem 1.0625rem 0.9rem;
        border: 0.063rem solid #ffffff;
        border-radius: 0.313rem;

        @media (max-width: 768px) {
            margin-bottom: 0.625rem;
        }
    }

    .btn-info-filter {
        background: $xantura_primary;
        margin-right: 0.625rem;
        color: #ffffff;
        width: max-content;
        padding: 0.9rem 1.0625rem 0.9rem;
        border: 0.063rem solid #D8D8D8;
        border-radius: 0.313rem;

        @media (max-width: 768px) {
            margin-bottom: 0.625rem;
        }
    }

    .main-content {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            display: flow;
        }
    }

    .select-section {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        width: 100%;

        @media (max-width: 768px) {
            display: flow;
            width: 100%;
        }

    }

    .button-section {
        display: flex;
        align-content: center;

        @media (max-width: 768px) {
            display: flow;
        }
    }

    .select-container {
        .select-button-wrapper {
            button {
                padding: 0.9rem 0.625rem;
                font-style: normal;
                font-size: 1rem;
                padding-right: 1rem !important;
                border-radius: 0.625rem;
                color: $xantura_primary;
                font-weight: 700;
            }
        }
    }

    .filter-fields {
        .select-container {
            ul {
                height: 200px;
            }
        }
    }

}