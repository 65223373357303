@import "_pallet.scss";

.case-filter-container {
    // border: 0.125rem solid #fff;
    // border-radius: 0.625rem;
    position: relative;

    &:not(:last-of-type) {
    margin-right: 1rem;
    }

    &::after {
        content: "";
        display: inline-block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 50%;
        right: 0.313rem;
        transform: translateY(-50%);
        background: url('../../icons/chevron_down_white.svg') no-repeat center/cover;
        pointer-events: none;
    }

    span {
        font-size: 1rem;
        font-family: $font-family-base;
        font-weight: 400;
        color: #fff;
        font-size: 1rem;
        font-weight: 400;
        font-family: $font-family-base, Arial, Helvetica, sans-serif;
        margin: 0 1.063rem;
        position: absolute;
        left: 2%;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .select-container {
        .select-button-wrapper {

            button {
                min-width: 9.375rem;
                background-color: transparent;
                border: 0;
                padding: 0.325rem 2.813rem 0.325rem 5.25rem;
                border: 0.125rem solid #fff;
                border-radius: 0.625rem;
                text-align: center;
                color: #fff;

                &.title-hide {
                    padding: 0.325rem 2.813rem 0.325rem 1.25rem;
                }
            }

        }

        ul {
            list-style-type: none;
            padding-left: 0;
            height: 150px;
            overflow: auto;
            margin-bottom: 0;
            padding: 0.625rem 1rem;
            position: absolute;
            width: 100.2%;
            top: 94%;
            left: -0.5px;
            background-color: #fff;
            z-index: 2;
            border: 0.063rem solid $xantura_primary;
            box-shadow: 0 0.313rem 0.25rem #D8D8D8;
            border-top: 0;
            border-bottom-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                padding: 0.75rem 0;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #D8D8D8;
                }

                button {
                    background-color: transparent;
                    width: 100%;
                    border: 0;
                    box-shadow: 0;
                    text-align: left;
                    font-weight: 700;
                    color: $xantura_primary;

                }
            }
        }
    }

}