.community-assets-toolbar {
    .toolbar-wrapper {
        display: flex;
        justify-content: space-between;

        .toolbar-container {
            padding: 1.2rem 0 1.2rem 1.2rem;
        }

        .toolbar-button-container {
            padding: 3.1rem 0 1.2rem 1.2rem;

            &:last-child {
                padding-right: 3.2rem;
            
            }
        }
    }

    .toolbar-toggle-wrapper {
        padding: 3.1rem 1.2rem 1.2rem 1.2rem;
    }


}

.community-assets { 


.grid-wrapper {
    padding: 1.2rem;
} 


}


/* Define the keyframes */
@keyframes paddingRightAnimationIn {
    from {
        padding-right: 0;
    }
    to {
        padding-right: 2rem;
    }
}

/* Define the keyframes */
@keyframes paddingRightAnimationOut {
    from {
        padding-right: 0;
    }

    to {
        padding-right: 2rem;
    }
}


.community-assets-btn {
position: fixed;
right: 0;
bottom: 5rem;
padding: 1.5rem 0 1.5rem 1.5rem;

&:hover {
    .btn.btn-primary.map {
        padding-right: 2rem;
        // write animation function to make the padding-right transition smooth
        animation: paddingRightAnimationIn 0.3s ease-out;
    }
    }
}

.btn.btn-primary.map {
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.8rem;
    img {
        width: 2rem;
    }
}


.filter-header {

    .filter-header-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 1.2rem;
    }
    
    h6 {
        color: #fff;
    }
}