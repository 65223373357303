@import "_pallet";

.popup-container {

	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	margin: 0 auto;
	overflow: hidden;


	.popup {
		width: 35%;
		// height: 90vh;
        border: 1px solid #E6E7F2;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		border-radius: 1rem;
		position: absolute;
		background-color: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&.two-columns-container {
			width: 50%;
		}

		.two-column {
			overflow-y: scroll;
			height: 85%;
			padding-bottom: 6rem;

			&::-webkit-scrollbar {
				display: none;
			}

			.fields-group {
				justify-content: space-between;
				margin: 0 2.3rem;

				.filter-fields {
					flex-basis: 47%;
					margin-right: 0 !important;
					margin-left: 0 !important;
				}
			}

			.checkbox-container {
				margin: 0 2.3rem;
			}
		}

		.fields-group {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;

			.filter-fields {
				flex-basis: 21.3%;
				margin-top: 1.5rem;

				&:not(:nth-child(4n)) {
					margin-right: 3%;
				}

                &:nth-child(4n+1) {
					margin-left: 2.9%;
				}
			}
		}

		.create-case-popup {

			overflow-y: scroll;
			// height: calc(69% + 7vh);
			height: 85%;
			padding-bottom: 4.063rem;

			&::-webkit-scrollbar {
				display: none;
			}

			.title-container {
				border-radius: 0 !important;
                background-color: #E6E7F2 !important;
				align-items: center;
				padding: 0.5rem 2.7rem !important;
			}

			.filter-buttons {
				display: flex;
				justify-content: space-between;
				width: 93.2%;
				margin: 2rem auto 0;
			}

			.secondary-fields {
				margin-top: 2rem;
			}
		}


		button.blue-background {
			border: 1px solid #fff;
		}
	}

	.popup-title {
		background-color: $xantura_primary;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.5rem 2.2rem;
		border-top-right-radius: 11px;
		border-top-left-radius: 11px;

		.title {
			color: #fff;
			font-size: 1.563rem;
			font-weight: 700;
			font-family: $font-family-base, Arial, Helvetica, sans-serif;
			margin-bottom: 0;

		}
	}

	.close {
		background-color: transparent;
		width: 1.563rem;
		height: 1.563rem;
		border: none;
		outline: none;
		padding: 0;

		img {
			width: 100%;

		}
	}

	.background-footer {
        background-color: #E6E7F2;
		padding: 1rem 0;
		margin-top: 1.25rem;
	}

	.popup-footer {
		display: flex;
		justify-content: space-between;
		background-color: $xantura_primary;
		padding: 1rem;
		position: fixed;
		bottom: 0;
		width: 100%;
	}


	.button-wrapper {
		flex-basis: 27%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-basis: 32%;

		.save-data-button {
			width: 35%;
			background-color: #fff;
			border: 1px solid $xantura_primary;
			padding: 0.375rem 0.75rem;
			border-radius: 0.625rem;
			color: $xantura_primary;
			font-size: 1rem;
			font-weight: 600;
		}

		.save-dropdown {
			position: relative;
			flex-basis: 60%;

			.save-button {
				width: 100%;
				background-color: #fff;
				border: 1px solid $xantura_primary;
				padding: 0.375rem 0.75rem;
				border-radius: 0.625rem;
				color: $xantura_primary;
				font-size: 1rem;
				font-weight: 600;
			}

			ul {
				width: 100%;
				padding: 0;
				list-style-type: none;
				position: absolute;
				bottom: 95%;
				left: 0;
				padding: 0 1rem;
				background: #fff;
				margin-bottom: 0;
				border: 1px solid $xantura_primary;
				border-radius: 0.625rem;
				// border-top-right-radius: 0.625rem;
				// border-top-left-radius: 0.625rem;

				li {
					background-color: #fff;
					padding: 0.75rem 0;

					&:not(:last-of-type) {
                        border-bottom: 1px solid #D8D8D8;
					}

					button {
						width: 100%;
						border: 0;
						color: $xantura_primary;
						font-size: 1rem;
						font-weight: 700;
						background-color: #fff;
						font-family: $font-family-base, Arial, Helvetica, sans-serif;
					}
				}
			}
		}
	}


}