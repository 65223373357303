.main {
    padding: 0 2.625rem;

    .main-heading {
        align-items: center;
        padding-bottom: 1.9rem;

        @media (max-width: 1150px) {
            flex-wrap: wrap;
        }

        h4 {
            font-size: 1.875rem;
            font-weight: 700;
            color: #252C48;
            margin-bottom: 0;

            @media (max-width: 1150px) {
                flex-basis: 100%;
            }
        }
    }



    .mb-3 {
        margin-bottom: 1.5rem !important;
    }

    .title-container {
        align-items: center;
        border: 0.188rem solid #fff;
        padding: 0.813rem 0 0.813rem !important;

        .title {
            margin-left: 1.5rem;
        }

        button {
            margin-right: 1.188rem;
        }

        &.open-data {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            .title {
                color: #fff;
            }
        }


    }

    .collapsible-wpr {
        .tab-container {
            background-color: #fff;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            margin-bottom: 1rem;
            box-shadow: -2px 2px 4px rgba(216, 216, 216, 0.5);
            padding: 0.813rem 1.5rem 0 !important;

            .tab-content {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .data-button-container {
        padding-bottom: 0 !important;

        @media (max-width: 1180px) {
            padding-left: 0 !important;
        }

        button {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1rem;
            padding: 0.425rem 1.5rem;
            border: 0.125rem solid $xantura_primary;
        }

        .data-sharing {
            background-color: transparent;
            color: $xantura_primary;
            border: 2px solid $xantura_primary;

            @media (max-width: 1180px) {
                margin-left: 0 !important;
            }

        }

        .btn-feedback {
            background-color: transparent;
            color: $xantura_primary;
            border: 2px solid $xantura_primary;
            margin-right: 10px;

        }

        .btn-download {
            max-width: 200px;
        }

    }


}

.main.close {
    margin: 0 1rem !important;

    // TODO: min width of the side panel toggle button is reduced from 128px to 44px in two-column-layout component
    // h4 {
    //     margin-left: 5.6875rem;

    //     @media (max-width: 1280px) {
    //         margin-left: 8.6875rem;
    //     }
    // }
}