@import "_pallet";

.main {
    .nav-tabs {
        width: max-content;
        max-width: 100%;
        border: 0;
        margin: 0;
       

        .nav-item {
            background-color: #fff;

            &.active {

                button {
                    background-color: $xantura_primary;
                    color: #fff;
                    font-weight: 500;
                }
            }
            

            &:first-child {

                button {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }

            &:last-child {
                button {
                    border-right: 1px solid $xantura_primary;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }

            button {
                width: 100%;
                background-color: #fff;
                color: #252C48;
                padding: 0.625rem 1.5rem;
                border: 1px solid $xantura_primary;
                border-right: 0;
                outline: none;
                font-size: 1rem;
            }
        }
    }
}