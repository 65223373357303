@import "_pallet.scss";

.top-header {
    display: flex;
    justify-content: space-between;
    background-color: #E6E7F2;
    padding: 0 1.25rem;
    height: 4.063rem;
    position: fixed;
    width: 100%;
    z-index: 100;

    h1 {
        width: 21.5rem;
        margin-bottom: 0;
    }

    .logo {
        height: 90%;
        max-height: 44px;
        margin-bottom: 0;
    }

    .button-wrapper {
        width: 35rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-basis: 80%;
    }

    .select-container {
        background-color: transparent !important;
        min-width: 9.875rem;
        margin-left: 1rem;

        .open {
            button {
                border-bottom: none !important;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }

        .select-button-wrapper {

            &::after {
                background: url('../../icons/chevron_down_blue.svg') no-repeat center/cover !important;
            }

            button {
                padding: 0.325rem 1rem !important;
                font-size: 1rem;
                border-radius: 0.625rem;
                position: relative;
                padding-right: 3rem !important;
                font-style: normal;
                color: $xantura_primary;
                font-weight: 700;
                border: 1px solid $xantura_primary !important;

            }
        }

        .select-button-add {
            button {
                padding: 0.325rem 1rem !important;
                font-size: 1rem;
                border-radius: 0.625rem;
                position: relative;
                text-align: center;
                font-style: normal;
                color: $xantura_primary;
                font-weight: 700;
                border: 1px solid $xantura_primary !important;
            }
        }
    }


}