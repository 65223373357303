@import "_pallet.scss";

$borderRadius: 0.513rem;


.filter-fields {

    .title {
        font-size: 1.188rem;
        font-family: $font-family-base;
        font-weight: 700;
    }

    .select-container {
        position: relative;
        background-color: #fff;
        border-radius: $borderRadius;
        // border: 0.063rem solid #E7E7F1;
        box-shadow: 0 0 0.25rem #D8D8D8;

        &.open {
            button {
                color: $xantura_primary;
                font-weight: 700;
                font-style: normal;
                background: $xantura_primary10
            }

            .select-button-wrapper {
                border-radius: $borderRadius;
                border: 0.063rem solid $xantura_primary;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: 0;
            }
        }

        .select-button-wrapper {
            position: relative;

            button {
                padding: 0.625rem 1rem;
                padding-right: 2.5rem;
            }

            &::after {
                content: "";
                width: 2.5rem;
                height: 2.5rem;
                background: url('../../icons/chevron_down.svg') no-repeat center/cover;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }



        button {
            width: 100%;
            text-align: left;
            background-color: transparent;
            border: 0;
            color: #000;
            font-size: 1rem;
            font-weight: 400;
            font-style: italic;
            outline: none;
            box-shadow: none;
        }

        .ul-wrapper {
            max-height: 200px;
            overflow: auto;
            width: 100%;
            top: 90%;
            background-color: #fff;
            z-index: 10100;
            border: 0.063rem solid $xantura_primary;
            box-shadow: 0 0.313rem 0.25rem #D8D8D8;
            border-top: 0;
            border-bottom-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
            position: absolute;
            padding-bottom: 10px;

            &::-webkit-scrollbar {
                width: 0.438rem;
                border-radius: 0.625rem;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $xantura_primary;
                border-radius: 0.625rem;
                background-clip: padding-box;
            }

            ::-webkit-scrollbar-thumb:hover {
                border: 0;
            }

            &::-webkit-scrollbar-track {
                background-color: #E7E6E6;
                border-radius: 0.625rem;
            }
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            // padding: 0.625rem 1rem;
            // position: absolute;


            li {
                // padding: 0.45rem 0;
                padding: 0.725rem 1rem;
                display: flex;


                span.checkbox {
                    display: inline-block;
                    width: 1rem;
                    height: 1rem;
                    border: 1px solid #000000;
                    margin-top: 0.3rem;
                }

                >button {
                    display: inline-block;
                    background: transparent !important;
                }

                &.active {
                    background: $xantura_primary30;

                    span.checkbox {
                        display: inline-block;
                        width: 1rem;
                        height: 1rem;
                        border: 1px solid #ffffff;
                        background: $xantura_primary;
                    }
                }

                &:not(:last-of-type) {
                    border-bottom: 1px solid #D8D8D8;
                }
            }
        }


    }

    // select {
    //     appearance: none;
    //     width: 100%;
    //     padding: 0.625rem 1rem;
    //     border-radius: 0.313rem;
    //     border: 0.063rem solid #E7E7F1;
    //     box-shadow: 0 0 0.25rem #D8D8D8;
    //     font-size: 1.063rem;
    //     font-family: $font-family-base;
    //     font-style: italic;


    // }

}