@import "_pallet.scss";


.dashboard {
    .fixed-aside {
        // width: 31.5rem !important;
        top: 4rem;
        z-index: 1;
    }

    .search-filter-container {
        display: flex;
        justify-content: space-between;

        .cohorts-filter {


            .filter-fields {
                width: 100%;

                .select-container {
                    .select-button-wrapper {
                        button {
                            padding: 0.6rem 1rem;
                            font-size: 1.188rem;
                            font-style: normal;
                        }
                    }
                }
            }

        }

        .cohorts-filter,
        .search-filter {
            display: flex;
            flex: 0.48;
        }

        .search-filter {
            // flex-basis: 43.5%;
            position: relative;

            &::before {
                content: '';
                width: 1rem;
                height: 1rem;
                display: inline-block;
                background: url('../../icons/search-icon.png') no-repeat center/cover;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 1.3rem;
            }

            input {
                width: 100%;
                border: none;
                display: inline-block;
                font-size: 1.188rem;
                padding: 0.6rem 1rem;
                padding-left: 2.5rem;
                box-shadow: none;
                outline: none;
                border: 1px solid #E7E7F1;
                box-shadow: 0 0 4px #D8D8D8;
                font-family: $font-family-base, Arial, Helvetica, sans-serif;
                font-weight: 500;
                color: #000;
                border-radius: 0.313rem;
            }

            .btn-cta {
                padding: inherit 0.6rem;
            }
        }
    }

    .btn-text-color {
        color: $xantura_primary;
    }

    .filter-content {
        margin-top: 1.5rem;
        border-radius: 15px;
        overflow: hidden;

        &:first-child {
            margin-top: 0;
        }

        &.pos-relative {
            position: relative;
        }


        .filter-header {
            background-color: $xantura_primary;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.9rem;

            &.filter-disabled {
                background-color: #CCCC;
            }

            @media (max-width: 1220px) {
                flex-wrap: wrap;
            }

            h3 {
                font-size: 1.313rem;
                color: #fff;
                font-weight: 700;
                font-family: $font-family-base, Arial, Helvetica, sans-serif;
                margin-bottom: 0;

                @media (max-width: 1220px) {
                    flex-basis: 30%;
                    // margin-bottom: 1rem;
                }

                span {
                    font-weight: 400;
                }
            }

            .select-container {
                display: flex;
                justify-content: flex-start;
                flex-basis: 78%;
            }

            .button-list {

                display: flex;
                align-items: end;

                .btn {
                    border-radius: 0.625rem;
                }

                .btn-white {
                    border: 0.125rem solid #fff;
                    background-color: #fff;
                    color: $xantura_primary;
                }


                .space {
                    padding: 0 0.5rem;
                }
            }
        }

        &.case-details .content {
            background: #E6E7F2;



            .tab-content {
                padding: 0.3rem;
            }

            .tabs-header {
                background: $xantura_primary;

                .nav-tabs,
                .nav-item {
                    background: transparent
                }

                .nav-tabs {
                    .nav-item {
                        &.active {
                            button {
                                font-weight: bold;

                                &::after {
                                    content: "";
                                    display: block;
                                    width: 100%;
                                    border-bottom: 0.2rem solid #fff;
                                    margin-top: 2px;

                                }
                            }
                        }

                        button {
                            border: none;
                            background: inherit;
                            color: #fff;
                            border-radius: 0;
                        }
                    }
                }
            }

        }

        .content {
            background-color: #fff;

        }



        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            z-index: 99;
        }

    }



    .btn-border {
        border: 0.125rem solid #fff;
    }

    .btn-feedback {
        border: 0.125rem solid #ffff;
        color: #ffff;
    }

    .btn-header-feedback {
        border: 0.125rem solid #ffff;
        color: #ffff;
        width: 26%;
    }

    .btn- .map {
        width: 10.063rem;
        padding: 0.75rem 0 0.75rem 28px;
        background-color: $xantura_primary;
        position: fixed;
        right: 0;
        bottom: 6.75rem;
        border-top-left-radius: 1.563rem;
        border-bottom-left-radius: 1.563rem;
        cursor: pointer;

        img {
            width: 2.5rem;
            height: 2.313rem;
        }


    }


    .collapsible-wpr .title-container.open-data {
        background: #e6e7f2;

        h5 {
            color: #252C48 !important
        }
    }

    .edit-case .popup-container {
        position: fixed;
        z-index: 9999;
    }
}


.footer {
    padding: 0.8rem;
}

.no-data {
    background: #fff;
    padding: 2rem;
    border-radius: 1.25rem;

    p {
        margin: 0;
    }

}